.pairs-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.pair {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  width: calc(25% - 1rem);
  padding: 0.5rem 1rem;
  gap: 0.25rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-width: 15rem;
  min-height: 7rem;
  p:first-of-type {
    font-weight: bolder;
    font-size: 1.3rem;
    margin: 0;
  }

  p:last-of-type {
    font-size: 1.1rem;
    margin: 0;
  }

  .note {
    color: #666;
    font-size: 0.7rem;
  }
}

.pairs-title {
  margin: 1.1rem 0 1rem;
}