.updatePhoto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  .imageContainer {
    width: 5em;
    height: 5em;
    font-size: 1rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: contain;
    }
  }
  label {
    width: 100%;
  }
}
